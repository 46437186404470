export default class AdvancedSettingsData {
  public static read(key: string){
    const input = AdvancedSettingsData.getInput(key)
    if(!input) return
    if(input instanceof HTMLSelectElement || input.type === 'text') return input.value
    else return input.checked
  }

  public static write(key: string, value: string | boolean){
    const input = AdvancedSettingsData.getInput(key)
    if(!input) return
    if(input instanceof HTMLSelectElement || input.type === 'text') input.value = value as string
    else input.checked = value as boolean
  }

  private static getInput(key: string){
    let elements = AdvancedSettingsData.selectAllDefault(key)
    if(!elements.length) elements = AdvancedSettingsData.selectAllSettings(key)
    if(!elements.length) elements = AdvancedSettingsData.selectAllTemplate(key)
    return elements.find(el => el.getAttribute('type') !== 'hidden' && ['select', 'input'].includes(el.tagName.toLowerCase()))! as HTMLSelectElement | HTMLInputElement
  }

  private static selectAllDefault(key: string) {
    return Array.from(document.querySelectorAll(`[name="${AdvancedSettingsData.defaultNameSelector(key)}"]`))
  }

  private static selectAllSettings(key: string) {
    return Array.from(document.querySelectorAll(`[name="${AdvancedSettingsData.settingsNameSelector(key)}"]`))
  }

  private static selectAllTemplate(key: string) {
    return Array.from(document.querySelectorAll(`[name="${AdvancedSettingsData.templateNameSelector(key)}"]`))
  }

  private static defaultNameSelector(key: string) {
    return `template[versions_attributes][0][${key}]`
  }

  private static settingsNameSelector(key: string) {
    return `template[versions_attributes][0][settings][${key}]`
  }

  private static templateNameSelector(key: string) {
    return `template[${key}]`
  }
}
